import { FilesMutationTypes } from './types';

export const mutations = {
    [FilesMutationTypes.SetFiles](state, {files}) {
        return state.files.push(...files)
    },
    [FilesMutationTypes.SetResponse](state, {response}) {
        return state.response = response
    },
    [FilesMutationTypes.SetChangedFile](state, {file}) {
        const foundIndex = state.files.findIndex(item => item._id === file._id)
        return state.files.splice(foundIndex, 1, file)
    },
    [FilesMutationTypes.SetLoadedFiles](state, {files}) {
        return state.loadedFiles = files
    },
    [FilesMutationTypes.SetEmptyFiles](state) {
        return state.files = []
    },
    [FilesMutationTypes.SetDeletedFile](state, {id}) {
        const foundIndex = state.files.findIndex(item => item._id === id)
        return state.files.splice(foundIndex, 1)
    },
}