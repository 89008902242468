const events = [{
    path: '/events',
    name: 'Events',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "Events" */ '@/views/events/Events'),
    children: [
        {
            path: 'list',
            name: 'events-list',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Events List" */ '@/views/events/List'),
        },
        {
            path: 'list/:id',
            name: 'subevents-list',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Subevents List" */ '@/views/events/List'),
        },
        {
            path: 'add',
            name: 'add-event',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Add Event" */ '@/views/events/Add'),
        },                
    ]
}]

export {events}