/* eslint-disable no-unused-vars */
import { FilesActionTypes, FilesMutationTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [FilesActionTypes.GetFiles]({ commit }, { params }) {
        try {
            const { data } = await this.$api.getFiles.get({
                params: { ...params }
            })
            if (!data.error_message) {
                commit(FilesMutationTypes.SetFiles, {files: data.data.data})
                commit(FilesMutationTypes.SetResponse, {response: data.data})
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [FilesActionTypes.EditFile]({ commit }, { id, value }) {
        try {
            const { data } = await this.$api.editFile.patch({ id, body: {
                ...value
            } })
            if (!data.error_message) {
                commit(FilesMutationTypes.SetChangedFile, {file: data.data})
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [FilesActionTypes.AddFile]({ commit }, { files }) {
        try {
            const { data } = await this.$api.addFile.post({ 
                body: files, 
                headers: { "Content-Type":"multipart/form-data" } 
            })
            if (!data.error_message) {
                commit(FilesMutationTypes.SetLoadedFiles, {files: data.data})
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }

            return { data }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [FilesActionTypes.AddFileDetails]({ commit }, { details }) {
        try {
            const { data } = await this.$api.addFileDetails.post({ 
                body: details, 
            })
            if (!data.error_message) {
                commit(FilesMutationTypes.SetLoadedFiles, {files: data.data})
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [FilesActionTypes.DeleteFile]({ commit }, { id }) {
        try {
            const { data } = await this.$api.deleteFile.delete({ 
                body: { id: id } 
            })
            if (!data.error_message) {
                commit(FilesMutationTypes.SetDeletedFile, { id })
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    }
}