/* eslint-disable no-unused-vars */
import Vue from 'vue';
const $vm = Vue.prototype;

class BaseService {
    constructor(resource) {
        if (!resource) throw new Error('Path not provided');
        // this.baseUrl = 'api/v1/';
        // this.path = this.baseUrl + resource;
        this.path = resource; 
    }

    errorHandler(data) {
        return data;
    }

    url({ id }) {
        const path = this.path;
        return id ? path.concat('/', id) : path;
    }
}

class ReadOnlyService extends BaseService {
    constructor(resource) {
        super(resource)
    }

    async get({ 
        id = '', 
        params = {} 
    } = {}) {
        try {
            const url = this.url({ id });
            const { data } = await $vm.$http(url, {params: {...params}});
            return { data }; 
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

class ModelService extends BaseService {
    constructor(resource) {
        super(resource)
    }
    
    async post({ 
        method = 'POST', 
        headers, 
        id = '', 
        body = {} 
    } = {}) {
        try {
            const url = this.url({ id });
            const { data } = await $vm.$http(url, {
                method: method,
                data: body,
                headers: headers,
            })

            return { data };
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

class PatchService extends BaseService {
    constructor(resource) {
        super(resource)
    }
    
    async patch({ 
        method = 'PATCH', 
        headers, 
        id = '', 
        body = {} 
    } = {}) {
        try {
            const url = this.url({ id });
            
            const { data } = await $vm.$http(url, {
                method: method,
                data: body,
                headers: {...headers},
            })

            return { data };
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

class DeleteService extends BaseService {
    constructor(resource) {
        super(resource)
    }
    
    async delete({ 
        method = 'DELETE', 
        id = '', 
        body = {}
    } = {}) {
        try {
            const url = this.url({ id });
            
            const { data } = await $vm.$http(url, {
                method: method,
                data: body,
            })

            return { data };
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

// Authorization

class Login extends ModelService {
    constructor() {
        super('users/login')
    }
}
class Logout extends ModelService {
    constructor() {
        super('users/logout')
    }
}

class PingAuth extends ReadOnlyService {
    constructor() {
        super('auth/ping')
    }
}

//Users
class GetUsers extends ReadOnlyService {
    constructor() {
        super('users/collection')
    }
}

class GetUser extends ReadOnlyService {
    constructor() {
        super('users/user')
    }
}

class AddUser extends ModelService {
    constructor() {
        super('users/add')
    }
}

class EditUser extends PatchService {
    constructor() {
        super('users/edit')
    }
}

class DeleteUser extends DeleteService {
    constructor() {
        super('users/remove')
    }
}

class FindUser extends ReadOnlyService {
    constructor() {
        super('users/find')
    }
}

// Files

class GetFiles extends ReadOnlyService {
    constructor() {
        super('files/get-details')
    }
}

class PostFiles extends ModelService {
    constructor() {
        super('files/add-file')
    }
}

class PostFileDetails extends ModelService {
    constructor() {
        super('files/add-details')
    }
}

class EditFile extends PatchService {
    constructor() {
        super('files/edit')
    }
}

class DeleteFile extends DeleteService {
    constructor() {
        super('files/remove')
    }
}

//Categories
class AddCategory extends ModelService {
    constructor() {
        super('categories/add')
    }
}

class GetCategories extends ReadOnlyService {
    constructor() {
        super('categories/collection')
    }
}

class EditCategory extends PatchService {
    constructor() {
        super('categories/edit')
    }
}

class DeleteCategory extends DeleteService {
    constructor() {
        super('categories/remove')
    }
}

//Employee
class GetEmployees extends ReadOnlyService {
    constructor() {
        super('employee/collection')
    }
}

class AddEmployee extends ModelService {
    constructor() {
        super('employee/add')
    }
}

class EditEmployee extends PatchService {
    constructor() {
        super('employee/edit')
    }
}

class DeleteEmployee extends DeleteService {
    constructor() {
        super('employee/remove')
    }
}

//Companies
class AddCompany extends ModelService {
    constructor() {
        super('company/add')
    }
}

class GetCompanies extends ReadOnlyService {
    constructor() {
        super('company/collection')
    }
}

class EditCompany extends PatchService {
    constructor() {
        super('company/edit')
    }
}

class DeleteCompany extends DeleteService {
    constructor() {
        super('company/remove')
    }
}

//Events
class AddEvent extends ModelService {
    constructor() {
        super('events/add')
    }
}

class GetEvents extends ReadOnlyService {
    constructor() {
        super('events/collection')
    }
}

class EditEvent extends PatchService {
    constructor() {
        super('events/edit')
    }
}

class DeleteEvent extends DeleteService {
    constructor() {
        super('events/remove')
    }
}

//User Posts
class AddUserPost extends ModelService {
    constructor() {
        super('user_post/add')
    }
}

class GetUserPosts extends ReadOnlyService {
    constructor() {
        super('user_post/collection')
    }
}

class EditUserPost extends PatchService {
    constructor() {
        super('user_post/edit')
    }
}

class DeleteUserPost extends DeleteService {
    constructor() {
        super('user_post/remove')
    }
}

//Likes
class AddLike extends ModelService {
    constructor() {
        super('likes/add')
    }
}

class EditLike extends PatchService {
    constructor() {
        super('likes/edit')
    }
}

export const $api = {
    login: new Login(),
    logout: new Logout(),
    PingAuth: new PingAuth(),
    getUsers: new GetUsers(),
    getUser: new GetUser(),
    addUser: new AddUser(),
    editUser: new EditUser(),
    deleteUser: new DeleteUser(),
    getFiles: new GetFiles(),
    editFile: new EditFile(),
    addFile: new PostFiles(),
    addFileDetails: new PostFileDetails(),
    deleteFile: new DeleteFile(),
    addCategory: new AddCategory(),
    getCategories: new GetCategories(),
    editCategory: new EditCategory(),
    deleteCategory: new DeleteCategory(),
    getEmployees: new GetEmployees(),
    addEmployee: new AddEmployee(),
    editEmployee: new EditEmployee(),
    deleteEmployee: new DeleteEmployee(),
    addCompany: new AddCompany(),
    getCompanies: new GetCompanies(),
    editCompany: new EditCompany(),
    deleteCompany: new DeleteCompany(),
    addEvent: new AddEvent(),
    getEvents: new GetEvents(),
    editEvent: new EditEvent(),
    deleteEvent: new DeleteEvent(),
    findUser: new FindUser(),
    addUserPost: new AddUserPost(),
    getUserPosts: new GetUserPosts(),
    editUserPost: new EditUserPost(),
    deleteUserPost: new DeleteUserPost(),
    addLike: new AddLike(),
    editLike: new EditLike(),
}