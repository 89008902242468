const categories = [{
    path: '/categories',
    name: 'categories',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "Categories" */ '@/views/categories/Categories'),
    children: [
        {
            path: 'list',
            name: 'categories-list',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Categories List" */ '@/views/categories/List'),
        },
        {
            path: 'add',
            name: 'add-category',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Add Category" */ '@/views/categories/Add'),
        },                
    ]
}]

export {categories}