import Vue from 'vue';
import Router from 'vue-router';
import { getUserLoginStatus } from '../helpers/authorization';
import { files } from './files';
import { categories } from './categories';
import { users } from './users';
import { company } from './company';
import { events } from './events';
import {userPosts} from './user-posts';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '/admin/',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/login',
            name: 'Login',
            meta: {
                auth: false,
            },
            component: () => 
                import(/* webpackChunkName: "Login" */ '@/views/Login'),
        },
        {
            path: '/',
            name: 'Home',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Home" */ '@/views/Home'),
        },
        ...files,
        ...categories,
        ...users,
        ...company,
        ...events,
        ...userPosts,
    ]
})

router.beforeEach((to, from, next) => {
    const user_login_status = getUserLoginStatus()    
    if (!to.meta.auth) {
        if (user_login_status) {
            return next({
                name: 'Home',
            })
        }

        return next();
    } else {
        if (!user_login_status) {
            return next({
                name: 'Login',
            })
        }
    
        return next();
    }
})

export default router