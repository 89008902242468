const userPosts = [{
    path: '/user-posts',
    name: 'UsersPost',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "User Posts" */ '@/views/user-posts/UserPosts'),
    children: [
        {
            path: 'list',
            name: 'user-posts-list',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "User Posts List" */ '@/views/user-posts/List'),
        },
        {
            path: 'add',
            name: 'add-user-post',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Add User Post" */ '@/views/user-posts/Add'),
        },
        {
            path: ':id',
            name: 'user-post-page',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "User Post Page" */ '@/views/user-posts/Page'),
        }              
    ]
}]

export {userPosts}