const EventsNameSpace = 'events';

const EventsActionTypes = Object.freeze({
    AddEvent: 'AddEvent',   
    GetEvents: 'GetEvents',
    EditEvent: 'EditEvent',
    DeleteEvent: 'DeleteEvent',
});

const EventsMutationTypes = Object.freeze({
    SetEvents: 'SetEvents',
    SetChangedEvent: 'SetChangedEvent',
    RemoveEventsFromStore: 'RemoveEventsFromStore',
    SetParentWithNoChildren: 'SetParentWithNoChildren',
    SetEmptyEvents: 'SetEmptyEvents',
});

const EventsGetterTypes = Object.freeze({
    GetEvents: 'GetEvents',
    GetParentWithNoChildren: 'GetParentWithNoChildren',
});

export {
    EventsNameSpace,
    EventsActionTypes,
    EventsMutationTypes,
    EventsGetterTypes,
};