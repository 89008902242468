import { FilesGetterTypes } from './types';

export const getters = {
    [FilesGetterTypes.GetFiles](state) {
        return state.files
    },
    [FilesGetterTypes.GetLoadedFiles](state) {
        return state.loadedFiles
    },
    [FilesGetterTypes.GetResponse](state) {
        return state.response
    }
}