const EmployeeNameSpace = 'employee';

const EmployeeActionTypes = Object.freeze({
    GetEmployees: 'GetEmployees',
    EditEmployee: 'EditEmployee',
    AddEmployee: 'AddEmployee',
    DeleteEmployee: 'DeleteEmployee',
});

const EmployeeMutationTypes = Object.freeze({
    SetEmployees: 'SetEmployees',
    SetChangedEmployee: 'SetChangedEmployee',
    SetDeletedEmployee: 'SetDeletedEmployee',
    SetEmptyEmployees: 'SetEmptyEmployees', 
});

const EmployeeGetterTypes = Object.freeze({
    GetEmployees: 'GetEmployees',
});

export {
    EmployeeNameSpace,
    EmployeeActionTypes,
    EmployeeMutationTypes,
    EmployeeGetterTypes,
};