import { EventsMutationTypes } from './types';

export const mutations = {
    [EventsMutationTypes.SetEvents](state, {data}) {
        if (state.events.length) {
            data.forEach(item => {
                state.events.splice(state.events.length - 1, 0, item)
            })
        } else {
            state.events = [...data]
        }
    },
    [EventsMutationTypes.SetChangedEvent](state, {event}) {
        const foundIndex = state.events.findIndex(item => item._id === event._id)
        return state.events.splice(foundIndex, 1, event)
    },
    [EventsMutationTypes.RemoveEventsFromStore](state, {parent_id}) {        
        state.events.find((item, index) => {
            if (item && item.ancestors && item.ancestors.some(e => e._id === parent_id)) {
                state.events.splice(index, 1)
            }
        })

        const parentIndex = state.events.findIndex(item => item._id === parent_id)
        state.events.splice(parentIndex, 1)
    },
    [EventsMutationTypes.SetParentWithNoChildren](state, {parent}) {
        return state.parent_with_no_children = {state: !state.parent_with_no_children.state, id: parent}
    },
    [EventsMutationTypes.SetEmptyEvents](state) {
        return state.events = []
    },
}