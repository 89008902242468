const CategoriesNameSpace = 'categories';

const CategoriesActionTypes = Object.freeze({
    AddCategory: 'AddCategory',   
    GetCategories: 'GetCategories',
    EditCategory: 'EditCategory',
    DeleteCategory: 'DeleteCategory',
});

const CategoriesMutationTypes = Object.freeze({
    SetCategories: 'SetCategories',
    SetChangedCategory: 'SetChangedCategory',
    RemoveCategoriesFromStore: 'RemoveCategoriesFromStore',
    SetParentWithNoChildren: 'SetParentWithNoChildren',
    SetEmptyCategories: 'SetEmptyCategories',
});

const CategoriesGetterTypes = Object.freeze({
    GetCategories: 'GetCategories',
    GetParentWithNoChildren: 'GetParentWithNoChildren',
});

export {
    CategoriesNameSpace,
    CategoriesActionTypes,
    CategoriesMutationTypes,
    CategoriesGetterTypes,
};