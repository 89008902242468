import { CompanyMutationTypes } from './types';

export const mutations = {
    [CompanyMutationTypes.SetCompanies](state, {data}) {
        if (state.companies.length) {
            data.forEach(item => {
                state.companies.splice(state.companies.length - 1, 0, item)
            })
        } else {
            state.companies = [...data]
        }
    },
    [CompanyMutationTypes.SetChangedCompany](state, {company}) {
        const foundIndex = state.companies.findIndex(item => item._id === company._id)
        return state.companies.splice(foundIndex, 1, company)
    },
    [CompanyMutationTypes.RemoveCompaniesFromStore](state, {parent_id}) {        
        state.companies.find((item, index) => {
            if (item && item.ancestors && item.ancestors.some(e => e._id === parent_id)) {
                state.companies.splice(index, 1)
            }
        })

        const parentIndex = state.companies.findIndex(item => item._id === parent_id)
        state.companies.splice(parentIndex, 1)
    },
    [CompanyMutationTypes.SetParentWithNoChildren](state, {parent}) {
        return state.parent_with_no_children = {state: !state.parent_with_no_children.state, id: parent}
    },
    [CompanyMutationTypes.SetEmptyCompanies](state) {
        return state.companies = []
    },
}