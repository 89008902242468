const files = [{
    path: '/files',
    name: 'Files',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "Files" */ '@/views/files/Files'),
    children: [
        {
            path: 'list',
            name: 'files-list',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Files List" */ '@/views/files/List'),
        },
        {
            path: 'add',
            name: 'add-files',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Add Files" */ '@/views/files/Add'),
        },                
    ]
}]

export {files}