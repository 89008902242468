const users = [{
    path: '/users',
    name: 'Users',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "Users" */ '@/views/users/Users'),
    children: [
        {
            path: 'list',
            name: 'users-list',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Users List" */ '@/views/users/List'),
        },
        {
            path: 'add',
            name: 'add-users',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Add Users" */ '@/views/users/Add'),
        },                
    ]
}]

export {users}