import { AuthorizationActionTypes } from './types';
import { setUserAuthData, setUserLoginStatus } from '../../helpers/authorization';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [AuthorizationActionTypes.Login](context, { email, password }) {
        return new Promise((resolve, reject) => {
            this.$api.login.post({
                body: { email, password },
            }).then(({data}) => {
                if (!data.error_message) {
                    setUserAuthData({ data: data.data })
                    setUserLoginStatus()

                    notifySuccess({ title: data.message })

                    window.open('/admin', '_self')

                    resolve({data})
                } else {
                    notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data.error_message)
                }
            })
            .catch((error) => {
                notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [AuthorizationActionTypes.PingAuth]() {
        try {
            const { data } = await this.$api.PingAuth.get()
            
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })  
            }
        } catch (error) {
            notifyDefaultError({ error })
        }            
    },
}