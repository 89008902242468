const LikesNameSpace = 'likes';

const LikesActionTypes = Object.freeze({
    EditLike: 'EditLike',
    AddLike: 'AddLike',
});

const LikesMutationTypes = Object.freeze({});

const LikesGetterTypes = Object.freeze({});

export {
    LikesNameSpace,
    LikesActionTypes,
    LikesMutationTypes,
    LikesGetterTypes,
};