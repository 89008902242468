import { CategoriesMutationTypes } from './types';

export const mutations = {
    [CategoriesMutationTypes.SetCategories](state, {data}) {
        if (state.categories.length) {
            data.forEach(item => {
                state.categories.splice(state.categories.length - 1, 0, item)
            })
        } else {
            state.categories = [...data]
        }
    },
    [CategoriesMutationTypes.SetChangedCategory](state, {category}) {
        const foundIndex = state.categories.findIndex(item => item._id === category._id)
        return state.categories.splice(foundIndex, 1, category)
    },
    [CategoriesMutationTypes.RemoveCategoriesFromStore](state, {parent_id}) {        
        state.categories.find((item, index) => {
            if (item && item.ancestors && item.ancestors.some(e => e._id === parent_id)) {
                state.categories.splice(index, 1)
            }
        })

        const parentIndex = state.categories.findIndex(item => item._id === parent_id)
        state.categories.splice(parentIndex, 1)
    },
    [CategoriesMutationTypes.SetParentWithNoChildren](state, {parent}) {
        return state.parent_with_no_children = {state: !state.parent_with_no_children.state, id: parent}
    },
    [CategoriesMutationTypes.SetEmptyCategories](state) {
        return state.categories = []
    },
}