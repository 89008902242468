const company = [{
    path: '/company',
    name: 'company',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "Company" */ '@/views/company/Company'),
    children: [
        {
            path: 'list',
            name: 'company-list',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Company List" */ '@/views/company/List'),
        },
        {
            path: 'employees',
            name: 'employees-list',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Employees" */ '@/views/company/EmployeeList'),
        },
        {
            path: 'add-employee',
            name: 'add-employee',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Add Employee" */ '@/views/company/AddEmployee'),
        },                
    ]
}]

export {company}