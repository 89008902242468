const FilesNameSpace = 'files';

const FilesActionTypes = Object.freeze({
    GetFiles: 'GetFiles',
    EditFile: 'EditFile',
    AddFile: 'AddFile',
    AddFileDetails: 'AddFileDetails',
    DeleteFile: 'DeleteFile',
});

const FilesMutationTypes = Object.freeze({
    SetFiles: 'SetFiles',
    SetResponse: 'SetResponse',
    SetChangedFile: 'SetChangedFile',
    SetLoadedFiles: 'SetLoadedFiles',
    SetEmptyFiles: 'SetEmptyFiles',
    SetDeletedFile: 'SetDeletedFile',
});

const FilesGetterTypes = Object.freeze({
    GetFiles: 'GetFiles',
    GetLoadedFiles: 'GetLoadedFiles', 
    GetResponse: 'GetResponse',
});

export {
    FilesNameSpace,
    FilesActionTypes,
    FilesMutationTypes,
    FilesGetterTypes,
};