const setUserLoginStatus = () => {
    const result = localStorage.setItem('loggedIn', JSON.stringify(1))
    return result
}

const getUserLoginStatus = () => {
    const result = JSON.parse(localStorage.getItem('loggedIn'))
    return result
}

const clearUserLoginStatus = () => {
    localStorage.removeItem('loggedIn')
}

const setUserAuthData = ({ data }) => {
    const result = localStorage.setItem('authData', JSON.stringify({...data}))
    return result
}

const getUserAuthData = () => {
    const result = JSON.parse(localStorage.getItem('authData'))
    return result
}

const clearUserAuthData = () => {
    localStorage.removeItem('authData')
}

const logOut = () => {
    clearUserAuthData()
    clearUserLoginStatus()
    window.open('/admin/login', '_self')
}

export { 
    setUserLoginStatus,
    getUserLoginStatus, 
    clearUserLoginStatus,
    setUserAuthData,
    getUserAuthData,
    clearUserAuthData,
    logOut,
}