import { EmployeeActionTypes, EmployeeMutationTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [EmployeeActionTypes.GetEmployees]({ commit }, { params }) {
        return new Promise((resolve, reject) => {
            this.$api.getEmployees.get({
                params: { ...params },
            }).then(({data}) => {
                if (!data.error_message) {
                    commit(`${EmployeeMutationTypes.SetEmployees}`, { employees: data.data.data })
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data.error_message)
                }
            })
            .catch((error) => {
                notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [EmployeeActionTypes.AddEmployee](context, { 
        user,
        department,
     } = {}) {
        try {
            const { data } = await this.$api.addEmployee.post({
                body: {
                    user,
                    department,
                }
            })
            
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })  
            } else {
                notifySuccess({ title: data.message })
            }

            return data
        } catch (error) {
            notifyDefaultError({ error })
        }            
    },
    async [EmployeeActionTypes.EditEmployee]({ commit }, { id, value }) {
        try {
            const { data } = await this.$api.editEmployee.patch({ id, body: {
                ...value
            } })
            if (!data.error_message) {
                commit(EmployeeMutationTypes.SetChangedEmployee, {employee: data.data})
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [EmployeeActionTypes.DeleteEmployee]({ commit }, { id }) {
        try {
            const { data } = await this.$api.deleteEmployee.delete({ 
                body: { id: id } 
            })
            if (!data.error_message) {
                commit(EmployeeMutationTypes.SetDeletedEmployee, { id })
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    }
}