import { EmployeeMutationTypes } from './types';

export const mutations = {
    [EmployeeMutationTypes.SetEmployees](state, {employees}) {
        return state.employees = employees
    },
    [EmployeeMutationTypes.SetEmptyEmployees](state) {
        return state.employees = []
    },
    [EmployeeMutationTypes.SetChangedEmployee](state, {employee}) {
        const foundIndex = state.employees.findIndex(item => item._id === employee._id)
        return state.employees.splice(foundIndex, 1, employee)
    },
    [EmployeeMutationTypes.SetDeletedEmployee](state, {id}) {
        const foundIndex = state.employees.findIndex(item => item._id === id)
        return state.employees.splice(foundIndex, 1)
    },
}
