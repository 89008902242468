import { CompanyActionTypes, CompanyMutationTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [CompanyActionTypes.AddCompany](context, { params }) {
        try {
            const { data } = await this.$api.addCompany.post({
                body: {...params}
            })
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                notifySuccess({ title: data.message })
            }

            return data
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [CompanyActionTypes.GetCompanies]({ commit }, { params = {}, readonly = false }) {
        try {
            const { data } = await this.$api.getCompanies.get({
                params: {...params}
            })
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                if (!readonly) commit(CompanyMutationTypes.SetCompanies, { data: data.data.data })
                notifySuccess({ title: data.message }) 
            }
            
            return data
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [CompanyActionTypes.EditCompany]({ commit }, { id, params }) {
        try {
            const { data } = await this.$api.editCompany.patch({
                id, body: {...params}
            })
            
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                commit(CompanyMutationTypes.SetChangedCompany, { company: data.data })
                notifySuccess({ title: data.message }) 
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [CompanyActionTypes.DeleteCompany]({ commit }, { id, parent }) {
        try {
            const { data } = await this.$api.deleteCompany.delete({ 
                id: id 
            })
            if (!data.error_message) {
                commit(CompanyMutationTypes.RemoveCompaniesFromStore, { parent_id: data.data.id })
                commit(CompanyMutationTypes.SetParentWithNoChildren, { parent })
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    }
}