const CompanyNameSpace = 'company';

const CompanyActionTypes = Object.freeze({
    AddCompany: 'AddCompany',   
    GetCompanies: 'GetCompanies',
    EditCompany: 'EditCompany',
    DeleteCompany: 'DeleteCompany',
});

const CompanyMutationTypes = Object.freeze({
    SetCompanies: 'SetCompanies',
    SetChangedCompany: 'SetChangedCompany',
    RemoveCompaniesFromStore: 'RemoveCompaniesFromStore',
    SetParentWithNoChildren: 'SetParentWithNoChildren',
    SetEmptyCompanies: 'SetEmptyCompanies',
});

const CompanyGetterTypes = Object.freeze({
    GetCompanies: 'GetCompanies',
    GetParentWithNoChildren: 'GetParentWithNoChildren',
});

export {
    CompanyNameSpace,
    CompanyActionTypes,
    CompanyMutationTypes,
    CompanyGetterTypes,
};