import { EventsActionTypes, EventsMutationTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [EventsActionTypes.AddEvent](context, { params }) {
        try {
            const { data } = await this.$api.addEvent.post({
                body: {...params}
            })
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                notifySuccess({ title: data.message })
            }

            return data
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [EventsActionTypes.GetEvents]({ commit }, { params = {}, readonly = false }) {
        try {
            const { data } = await this.$api.getEvents.get({
                params: {...params}
            })
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                if (!readonly) commit(EventsMutationTypes.SetEvents, { data: data.data.data })
                notifySuccess({ title: data.message }) 
            }
            
            return data
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [EventsActionTypes.EditEvent]({ commit }, { id, params }) {
        try {
            const { data } = await this.$api.editEvent.patch({
                id, body: {...params}
            })
            
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                commit(EventsMutationTypes.SetChangedEvent, { category: data.data })
                notifySuccess({ title: data.message }) 
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [EventsActionTypes.DeleteEvent]({ commit }, { id, parent }) {
        try {
            const { data } = await this.$api.deleteEvent.delete({ 
                id: id 
            })
            if (!data.error_message) {
                commit(EventsMutationTypes.RemoveEventsFromStore, { parent_id: data.data.id })
                commit(EventsMutationTypes.SetParentWithNoChildren, { parent })
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    }
}