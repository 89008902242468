import Vue from 'vue';
import Vuex from 'vuex';
import { state } from './state';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

//Modules
import authorization from "./authorization";
import files from "./files";
import categories from "./categories";
import users from "./users";
import employee from './employee';
import company from './company';
import events from './events';
import userposts from './user-posts';
import likes from './likes';

import api from '../plugins/index';
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [api],
  state,
  getters,
  mutations,
  actions,
  modules: {
    authorization,
    files,
    categories,
    users,
    employee,
    company,
    events,
    userposts,
    likes,
  }
})
