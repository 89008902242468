import { CategoriesActionTypes, CategoriesMutationTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [CategoriesActionTypes.AddCategory](context, { params }) {
        try {
            const { data } = await this.$api.addCategory.post({
                body: {...params}
            })
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                notifySuccess({ title: data.message })
            }

            return data
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [CategoriesActionTypes.GetCategories]({ commit }, { params = {}, readonly = false }) {
        try {
            const { data } = await this.$api.getCategories.get({
                params: {...params}
            })
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                if (!readonly) commit(CategoriesMutationTypes.SetCategories, { data: data.data.data })
                notifySuccess({ title: data.message }) 
            }
            
            return data
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [CategoriesActionTypes.EditCategory]({ commit }, { id, params }) {
        try {
            const { data } = await this.$api.editCategory.patch({
                id, body: {...params}
            })
            
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                commit(CategoriesMutationTypes.SetChangedCategory, { category: data.data })
                notifySuccess({ title: data.message }) 
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [CategoriesActionTypes.DeleteCategory]({ commit }, { id, parent }) {
        try {
            const { data } = await this.$api.deleteCategory.delete({ 
                id: id 
            })
            if (!data.error_message) {
                commit(CategoriesMutationTypes.RemoveCategoriesFromStore, { parent_id: data.data.id })
                commit(CategoriesMutationTypes.SetParentWithNoChildren, { parent })
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    }
}