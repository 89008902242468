import { UserPostsMutationTypes } from './types';

export const mutations = {
    [UserPostsMutationTypes.SetUserPosts](state, {user_posts}) {
        return state.user_posts.push(...user_posts)
    },
    [UserPostsMutationTypes.SetEmptyUserPost](state) {
        return state.user_posts = []
    },
    [UserPostsMutationTypes.SetUserPostsResponse](state, {response}) {
        return state.response = response
    },
    [UserPostsMutationTypes.SetChangedUserPost](state, {user_post}) {
        const foundIndex = state.user_posts.findIndex(item => item._id === user_post._id)
        return state.user_posts.splice(foundIndex, 1, user_post)
    },
    [UserPostsMutationTypes.SetDeletedUserPost](state, {id}) {
        const foundIndex = state.user_posts.findIndex(item => item._id === id)
        return state.user_posts.splice(foundIndex, 1)
    },
}
