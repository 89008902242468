<template lang="pug">
  #app
    router-view
    notifications(position="bottom right", :duration='10000')
    v-dialog
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
// @import './assets/styles/main';
</style>
