import axios from 'axios';
import { 
    clearUserLoginStatus, 
    getUserAuthData, 
    setUserLoginStatus,
    setUserAuthData,
} from '../helpers/authorization';

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

instance.defaults.headers.common['Content-Type'] = 'application/json'

instance.interceptors.request.use(
    async req => {
        const ls = JSON.parse(localStorage.getItem('authData'))
        if (!ls?.token) {
            return req
        } else {
            req.headers['Authorization'] = 'Bearer ' + ls.token
            
            return req
        }
    }
)

instance.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        try {
            if (error.response.status === 401) {         
                clearUserLoginStatus()

                const ls = getUserAuthData()     
                const {data} = await axios({
                    method: 'GET',
                    baseURL: process.env.VUE_APP_BASE_URL,
                    url: 'auth/get-refresh-token-pair',
                    headers: { 'Authorization': `Bearer ${ls.refresh_token}` },
                })
                
                setUserLoginStatus()

                instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.token
                const auth_data = getUserAuthData()
                auth_data.token = data.data.token
                auth_data.refresh_token = data.data.refresh_token
                setUserAuthData({ data: auth_data })

                return instance(error.config)
            } else {
                return Promise.reject(error)
            }
        } catch (error) {
            console.log(error.message)
        }
    }
)

export default instance
