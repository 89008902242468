const UsersNameSpace = 'users';

const UsersActionTypes = Object.freeze({
    GetUsers: 'GetUsers',
    GetUser: 'GetUser',
    AddUser: 'AddUser',
    EditUser: 'EditUser',
    DeleteUser: 'DeleteUser',
    FindUser: 'FindUser',
});

const UsersMutationTypes = Object.freeze({
    SetUsers: 'SetUsers',
    SetChangedUser: 'SetUser',
    SetDeletedUser: 'SetDeletedUser',
    SetEmptyUsers: 'SetEmptyUsers',
});

const UsersGetterTypes = Object.freeze({
    GetUsers: 'GetUsers',
});

export {
    UsersNameSpace,
    UsersActionTypes,
    UsersMutationTypes,
    UsersGetterTypes,
};