import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store/store';

//Libs
import './libs/composition-api';
import { i18n } from './libs/i18n';
import axios from './libs/axios';
import './libs/vue-moment';
import './libs/inline-svg';
import './libs/vue-js-modal';
import './libs/vue-autocomplete';
import './libs/vue-select';
import './libs/moment';

import Notifications from 'vue-notification'
Vue.use(Notifications);

//Config
// import './config/validation';

//CSS Styles
import './assets/styles/tailwind.css';

//Plugins
import './plugins';

Vue.prototype.$http = axios
Vue.config.productionTip = true

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')


i18n.locale = 'ru'