import { LikesActionTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [LikesActionTypes.AddLike](context, { params }) {
        try {
            const { data } = await this.$api.addLike.post({
                body: {...params}
            })
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                notifySuccess({ title: data.message })
            }

            return data
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [LikesActionTypes.EditLike](context, { id, params }) {
        try {
            const { data } = await this.$api.editLike.patch({
                id, body: {...params}
            })
            
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })
            } else {
                notifySuccess({ title: data.message }) 
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
}