import { UsersActionTypes, UsersMutationTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [UsersActionTypes.GetUsers]({ commit }, { params }) {
        return new Promise((resolve, reject) => {
            this.$api.getUsers.get({
                params: { ...params },
            }).then(({data}) => {
                if (!data.error_message) {
                    commit(`${UsersMutationTypes.SetUsers}`, { users: data.data.data })
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data.error_message)
                }
            })
            .catch((error) => {
                notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [UsersActionTypes.AddUser](context, { 
        email,
        password,
        role,
        status,
        group,
        name,
        lastname,
        patronymic,
        photo,
        photo_path,
        date_of_birth,
     } = {}) {
        try {
            const { data } = await this.$api.addUser.post({
                body: {
                    email,
                    password,
                    role,
                    status,
                    group,
                    name,
                    lastname,
                    patronymic,
                    photo,
                    photo_path,
                    date_of_birth,
                }
            })
            
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })  
            } else {
                notifySuccess({ title: data.message })
            }

            return data
        } catch (error) {
            notifyDefaultError({ error })
        }            
    },
    async [UsersActionTypes.EditUser]({ commit }, { id, value }) {
        try {
            const { data } = await this.$api.editUser.patch({ id, body: {
                ...value
            } })
            if (!data.error_message) {
                commit(UsersMutationTypes.SetChangedUser, {user: data.data})
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [UsersActionTypes.DeleteUser]({ commit }, { id }) {
        try {
            const { data } = await this.$api.deleteUser.delete({ 
                body: { id: id } 
            })
            if (!data.error_message) {
                commit(UsersMutationTypes.SetDeletedUser, { id })
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [UsersActionTypes.FindUser]({ commit }, { search }) {
        return new Promise((resolve, reject) => {
            this.$api.findUser.get({
                params: { search },
            }).then(({data}) => {
                if (!data.error_message) {
                    commit(`${UsersMutationTypes.SetUsers}`, { users: data.data.data })
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data.error_message)
                }
            })
            .catch((error) => {
                notifyDefaultError({ error })
                reject(error)
            })
        })
    },
}