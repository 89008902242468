import { UsersMutationTypes } from './types';

export const mutations = {
    [UsersMutationTypes.SetUsers](state, {users}) {
        return state.users = users
    },
    [UsersMutationTypes.SetEmptyUsers](state) {
        return state.users = []
    },
    [UsersMutationTypes.SetChangedUser](state, {user}) {
        const foundIndex = state.users.findIndex(item => item._id === user._id)
        return state.users.splice(foundIndex, 1, user)
    },
    [UsersMutationTypes.SetDeletedUser](state, {id}) {
        const foundIndex = state.users.findIndex(item => item._id === id)
        return state.users.splice(foundIndex, 1)
    },
}
